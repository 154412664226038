import React from "react"
import "./Scroller.scss"

const Scroller = () => (
  <div class="arrow">
    <span></span>
    <span></span>
    <span></span>
  </div>
)
export default Scroller
